<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
          <CCardHeader tag="div">
            <CIcon name="cil-grid"/>
            Đổi mật khẩu người dùng
          </CCardHeader>
          <CCardBody style="min-height: 300px" tag="div">
            <CForm>
              <CRow>
                <CCol lg="8">
                  <ValidationProvider name="Tên tài khoản" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseInput :disabled="true" label="Tên tài khoản" horizontal :value.sync="item.username" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="8">
                  <ValidationProvider name="Mật khẩu hiện tại" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseInput label="Mật khẩu hiện tại" horizontal :value.sync="item.oldPassword" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="8">
                  <ValidationProvider name="Mật khẩu mới" vid="pw_confirmation" rules="required"
                                      v-slot="{ errors, valid , touched }">
                    <BaseInput type="password" label="Mật khẩu mới" horizontal :value.sync="item.newPassword" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="8">
                  <ValidationProvider name="Xác nhận mật khẩu mới" rules="required|confirmed:pw_confirmation"
                                      v-slot="{ errors, valid , touched }">
                    <BaseInput type="password" label="Xác nhận mật khẩu mới" horizontal :value.sync="pw_confirmation" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
              </CRow>
            </CForm>
            <CElementCover v-if="isLoading"/>
          </CCardBody>
          <CCardFooter tag="div" class="text-right">
            <CButton color="secondary" class="mr-2" @click="cancel">Hủy bỏ</CButton>
            <CButton color="primary" @click="handleSubmit(saveItem)" :disabled="invalid || isChanging">Lưu lại</CButton>
          </CCardFooter>
        </ValidationObserver>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  CHANGEPASSWORD_APPLICATION_USER
} from '@/store/modules/application-user/actionTypes'

export default {
  name: 'ChangPassword',
  data () {
    return {
      isLoading: false,
      pw_confirmation: '',
      item: {}
    }
  },
  computed: {
    ...mapGetters('applicationUser', {
      isChanging: 'isChanging',
      responseResult: 'responseResult'
      // detailItem: 'detailItem'
    })
  },
  methods: {
    ...mapActions('applicationUser', {
      // getItem: GET_APPLICATION_USER,
      updateItem: CHANGEPASSWORD_APPLICATION_USER
    }),
    async saveItem () {
      await this.updateItem(this.item)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        await this.$router.push({ path: '/nguoi-dung' })
      }
    },
    cancel () {
      this.$router.push({ path: '/nguoi-dung' })
    },
    getUserInfo () {
      const dataUser = localStorage.getItem('authData')
      this.item.username = JSON.parse(dataUser).userName
    }
  },
  watch: {},
  async mounted () {
    this.isLoading = true
    // await this.getItem(this.$route.params.id)
    await this.getUserInfo()
    this.isLoading = false
  }
}
</script>
